import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, ThemeProvider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as Element } from 'react-scroll';

import Footer from 'ui-component/Footer';

import Indoor_Office from '../../../assets/images/Indoor_Office.webp';
import Icon_Project4 from '../../../assets/images/Icon_Project4.webp';
import DotsAccent3 from '../../../assets/images/DotsAccent3.webp';
import CFD from '../../../assets/videos/CFD2.mp4';
import Board from '../../../assets/images/Board.jpg';
import IAQ from '../../../assets/images/IAQ.webp';
import Grafana_UPCARE3 from '../../../assets/images/Grafana_UPCARE3.png';

import For_Industry from '../../../assets/images/For_Industry.jpg';
import For_Schools from '../../../assets/images/For_Schools.jpg';
import For_Individuals from '../../../assets/images/For_Individuals.jpg';

import { marginMobile, marginDesktop, projectTheme, InfoCard, marginDesktopBetween } from '../project1/projectTheme';
import { SolutionsSection, ContactSection, StartSection, ImportanceSection } from '../project1/projectTheme';

const People = () => {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const benefits = [
        {
            imageAlt: 'Industry',
            description: 'Good IAQ help ensure that employees and customers are not exposed to harmful pollutants',
            image: For_Industry,
        },
        {
            imageAlt: 'Schools',
            description: 'Good IAQ help ensure that students and teachers have a healthy and safe learning environment',
            image: For_Schools,
        },
        {
            imageAlt: 'Individuals',
            description:
                'From hospitals, malls, to our very own homes, IAQ monitoring will help everyone breathe cleaner air indoors',
            image: For_Individuals,
        },
    ];

    const infoCards = [
        {
            mediaType: 'video',
            src: CFD,
            alt: 'CARE Node',
            body: (
                <>
                    Part of effective IAQ monitoring is {isMediumScreen && <br />}
                    knowing where to properly place AQ sensors {isMediumScreen && <br />}
                    in an indoor environment. Project 4 addresses {isMediumScreen && <br />}
                    this problem through modeling techniques that {isMediumScreen && <br />}
                    simulate and predict indoor air behavior.
                </>
            ),
        },
        {
            mediaType: 'image',
            src: Board,
            alt: 'board',
            flexDir: 'row-reverse',
            body: (
                <>
                    Project 4 is using the locally-designed {isMediumScreen && <br />}
                    IAQ sensors from Project 1, which are capable{isMediumScreen && <br />}
                    of detecting NO2, O3, VOC, CO2, {isMediumScreen && <br />}
                    Formaldehyde, and PM2.5.
                </>
            ),
        },
        {
            src: Grafana_UPCARE3,
            alt: 'Dashboard',
            body: (
                <>
                    Data collected may be viewed and used via {isMediumScreen && <br />}
                    the IoT platform being developed by Project 1.{isMediumScreen && <br />}
                    Data will be made available to various {isMediumScreen && <br />}
                    stakeholders such as LGUs, NGOs,{isMediumScreen && <br />}
                    government agencies, and the public.
                </>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Project 4 | UP CARE</title>
            </Helmet>
            <ThemeProvider theme={projectTheme}>
                <div style={{ overflowX: 'hidden' }}>
                    <Element name="start">
                        <StartSection
                            backdrop={Indoor_Office}
                            icon={Icon_Project4}
                            projnum={4}
                            title={
                                <>
                                    Indoor Air Quality <br />
                                    Monitoring and Reporting System
                                </>
                            }
                            lead="Joseph Gerard Reyes, PhD"
                            overview={
                                <>
                                    Understanding airflow {isMobileScreen && <br />}
                                    and contaminant dispersion {isMobileScreen && <br />}
                                    in indoor environments {isMobileScreen && <br />}
                                    for safer homes, schools, and workplaces
                                </>
                            }
                            tags={['On-Going', 'TRL 3', 'IP Protection In-Progress']}
                        />
                    </Element>
                    <Element name="problem">
                        <Box
                            overflow={'hidden'}
                            display={'flex'}
                            justifyContent={'space-between'}
                            flexDirection="column"
                            marginRight={{ xs: marginMobile, lg: marginDesktop }}
                            paddingLeft={{ xs: marginMobile, lg: marginDesktop }}
                        >
                            {/* TOP problem */}
                            <Box
                                display={'flex'}
                                marginY={{ xs: marginMobile, md: marginDesktopBetween }}
                                minWidth={{ lg: '73ch' }}
                                maxwidth={'70%'}
                                justifyContent={{ xs: 'center', md: 'flex-end' }}
                                alignItems={{ md: 'flex-end' }}
                            >
                                <InfoCard
                                    title="What Problem Are We Working On?"
                                    body={
                                        <>
                                            Inadequate indoor air quality awareness and monitoring in the Philippines.
                                            <br />
                                            <br />
                                            People who stay in confined areas for prolonged periods{' '}
                                            {isMediumScreen && <br />}
                                            (such as office workers) face the risk of exposure to indoor air pollutants.{' '}
                                            {isMediumScreen && <br />}
                                            These contaminants are undetected by ordinary human senses.{' '}
                                            {isMediumScreen && <br />}
                                            Thus, people are unaware of the presence of these harmful compounds
                                            {isMediumScreen && <br />}
                                            and could even ingest them unknowingly when breathing.
                                        </>
                                    }
                                />
                            </Box>
                            {/* BOTTOM fact */}
                            <Box
                                position={'relative'}
                                display={'flex'}
                                justifyContent={{ xs: 'flex-end', md: 'flex-start' }}
                                paddingY={{ md: '2rem' }}
                                marginLeft={{ lg: '4rem' }}
                            >
                                <Box
                                    minWidth={{ xs: '20ch', md: '30ch' }}
                                    marginBottom={{ xs: marginMobile, lg: 0 }}
                                    marginTop={{ sm: '3rem', md: '0%' }}
                                    marginRight={{ sm: '10%', md: '0%' }}
                                    marginLeft={{ md: '5%', lg: '0%' }}
                                >
                                    <Box
                                        component="img"
                                        src={DotsAccent3}
                                        sx={{
                                            width: { xs: '50%', sm: '45%', lg: '50%' },
                                            opacity: '0.3',
                                            top: { xs: '-10%', md: '-10%', lg: '-30%' },
                                            left: { xs: '0%', sm: '10%', md: '50%' },
                                            transform: {
                                                xs: 'rotate(-50deg)',
                                                sm: 'rotate(-35deg)',
                                                md: 'scaleX(-1) rotate(-35deg)',
                                            },
                                        }}
                                        position="absolute"
                                    />
                                    <Box
                                        component="img"
                                        src={IAQ}
                                        sx={{
                                            maxWidth: { xs: '15rem', sm: '12rem', md: '20rem' },
                                            width: { xs: '40%', sm: '80%', md: '30%' },
                                            bottom: { xs: '0%', md: '120%', lg: '130%' },
                                            left: { xs: '0%', sm: '8%', md: '0%', lg: '-5%' },
                                        }}
                                        position="absolute"
                                    />
                                    <Typography
                                        fontSize={{ xs: '1rem', md: '1.5' }}
                                        fontStyle="italic"
                                        fontWeight={600}
                                        lineHeight="26px"
                                        color="#52584D"
                                        textAlign={{ xs: 'right', md: 'left' }}
                                    >
                                        People living in cities spend <br />
                                        about 90% of their time indoors, <br />
                                        which makes most of the air <br />
                                        they breathe "indoor air."
                                    </Typography>
                                    <Typography
                                        fontSize={'0.85rem'}
                                        letterSpacing={0.5}
                                        fontStyle="italic"
                                        fontWeight={300}
                                        lineHeight="20px"
                                        color="#52584D"
                                        textAlign={{ xs: 'right', md: 'left' }}
                                    >
                                        Source: US EPA (2023)
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Element>
                    <Element name="importance">
                        <ImportanceSection
                            benefits={benefits}
                            body={
                                <>
                                    Poor indoor air quality (IAQ) can lead to several {isDesktopScreen && <br />}
                                    health problems, including respiratory illnesses, {isDesktopScreen && <br />}
                                    allergies, and asthma. By monitoring IAQ, we can {isDesktopScreen && <br />}
                                    identify potential problems and address them {isDesktopScreen && <br />}
                                    before they become serious health hazards.
                                    <br />
                                    <br />
                                    Aside from health effects, poor IAQ can also {isDesktopScreen && <br />}
                                    place owners of indoor facilities at risk for {isDesktopScreen && <br />}
                                    potential lost revenue from losing tenants; {isDesktopScreen && <br />}there are
                                    also issues of tarnished image suffered {isDesktopScreen && <br />}
                                    by the owners and violation of statutory requirements {isDesktopScreen && <br />}
                                    for healthy workplaces.
                                </>
                            }
                        />
                    </Element>
                    <Element name="solutions">
                        <SolutionsSection infoCards={infoCards} />
                    </Element>
                    <Element name="contact">
                        <ContactSection
                            back="< Project 1"
                            backLink="/about-us/projects/1"
                            next="Project 5 >"
                            nextLink="/about-us/projects/5"
                        />
                    </Element>
                </div>
            </ThemeProvider>
            <Footer />
        </>
    );
};

export default People;
