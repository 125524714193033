import React from 'react';
import { Box, Button, Card, CardContent, CardMedia, Container, Divider, Paper, Stack, Typography } from '@mui/material';
import { Link as LinkDOM } from 'react-router-dom';

import Backdrop2_Join from '../../../assets/images/Backdrop2_Join.webp';
import DotsAccent2 from '../../../assets/images/DotsAccent2.webp';

import { createTheme, styled, useTheme, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

const useScreenBreakpoints = () => {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return { isDesktopScreen, isMediumScreen, isMobileScreen };
};

export default useScreenBreakpoints;

// TODO: Typography variants
// TODO: use theme colors

// Overall margin (in general)
export const marginDesktop = '8rem';
export const marginDesktopBetween = '3rem';
export const marginMobile = '2rem';

export const projectTheme = createTheme({
    palette: {
        primary: {
            main: '#00adb5',
            dark: '#00878d',
            contrastText: '#e69e4d',
        },
        secondary: {
            main: '#52584d',
            dark: '#000',
            contrastText: '#fff',
        },
        pastel: {
            pastelBlue: '#f2f9f9',
            pastelYellow: '#faf6ea',
            lightGray: 'f5f5f5',
        },
    },
    typography: {
        fontFamily: 'Lato, sans-serif', // Set Poppins as the default font, fallback to regular sans serif
    },
});

export const ContactButton = styled(Button)(({ theme }) => ({
    color: projectTheme.palette.primary.contrastText,
    fontWeight: 700,
    border: '2px solid #E69E4D',
    '&:hover': {
        backgroundColor: '#CFCBC6',
    },
}));

export const PagerButton = styled(Button)(({ theme }) => ({
    color: projectTheme.palette.primary.contrastText,
    fontSize: '0.7rem',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
        fontSize: '0.75rem',
    },
    whiteSpace: 'nowrap',
    '&:hover': {
        backgroundColor: '#CFCBC6',
    },
}));

export const TagTypography = styled(Typography)(({ theme }) => ({
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    color: projectTheme.palette.secondary.contrastText,
    backgroundColor: projectTheme.palette.primary.contrastText,
    borderRadius: '5px',
    padding: '0.3em 0.5em',
    whiteSpace: 'nowrap',
}));

export const SectionTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: '1.75rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.8rem',
        marginBottom: '2rem',
    },
    lineHeight: '125%',
    fontWeight: 600,
    textAlign: 'center',
    color: projectTheme.palette.secondary.main,
}));

export const SectionBodyPaper = styled(Paper)(({ theme }) => ({
    boxShadow: theme.shadows[3], // elevation={3}
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
        fontSize: '1.15rem',
    },
    [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem',
    },
    textAlign: 'center',
    lineHeight: '170%',
    padding: '1em',
    backgroundColor: projectTheme.palette.pastel.pastelBlue,
    color: projectTheme.palette.secondary.main,
}));

export const SectionIconCard = ({ icon: Icon, children }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                padding: '0.75rem',
                display: 'flex',
                alignItems: 'center',
                bgcolor: projectTheme.palette.pastel.pastelBlue,
            }}
        >
            <Stack direction="row" alignItems="center" spacing={2}>
                <Icon sx={{ fontSize: { xs: 45, sm: 60 } }} />
                <Typography variant="body1" fontSize={{ xs: '1rem', md: '1.15rem', lg: '1.25rem' }}>
                    {children}
                </Typography>
            </Stack>
        </Paper>
    );
};

export const StartSection = ({ backdrop, icon, projnum, title, lead, overview, tags }) => {
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                pt: { xs: 8, lg: 12 }, // previously-- pt: 12
                // pb: 5,
                display: 'flex',
                flexDirection: 'column',
                background: `linear-gradient( 180deg, 
                        rgba(0, 135, 141, 0.95) 33.33%, 
                        rgba(230, 158, 77, 0.95) 64.58%, 
                        rgba(0, 173, 181, 0.95) 100% )`,
                position: 'relative',
            }}
        >
            {/* backdrop layer */}
            <Box
                component="img"
                src={backdrop}
                sx={{
                    height: '100%',
                    opacity: 0.1,
                    top: '0',
                    right: '0',
                    transform: { xs: 'translateX(45%)', lg: 'none' },
                    clipPath: { xs: 'inset(0 45% 0 0)', lg: 'none' },
                    objectFit: 'cover',
                    display: 'block',
                    width: { xs: '210%', lg: '100%' },
                    alignContent: 'center',
                }}
                position="absolute"
            />
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                paddingY={marginDesktopBetween}
                flexDirection={{ xs: 'column', md: 'row' }}
            >
                {/* LEFT project details */}
                <Box marginLeft={{ xs: marginMobile, lg: marginDesktop }} marginRight={{ xs: marginMobile }}>
                    <Box
                        component="img"
                        src={icon}
                        sx={{
                            objectFit: 'cover',
                            width: '3rem',
                            marginLeft: { xs: '1rem', sm: '1.5rem' },
                        }}
                    />
                    <Typography
                        sx={{
                            // variant: 'h3',
                            textTransform: 'uppercase',
                            color: projectTheme.palette.secondary.contrastText,
                            fontWeight: 600,
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                        }}
                    >
                        {`Project ${projnum}`}
                    </Typography>
                    <Typography
                        py={'0.5em'}
                        sx={{
                            // variant: 'h2',
                            color: projectTheme.palette.secondary.contrastText,
                            lineHeight: '1.5',
                            fontSize: { xs: '1.25rem', sm: '1.5rem', lg: '1.75rem' },
                            fontWeight: 600,
                            maxWidth: { lg: '35vw' },
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        sx={{
                            color: projectTheme.palette.secondary.contrastText,
                            fontSize: '1rem',
                        }}
                    >
                        {`Lead: ${lead}`}
                    </Typography>
                </Box>
                {/* RIGHT project description */}
                <Box minWidth={{ sm: 'fit-content' }} marginRight={{ xs: marginMobile, lg: marginDesktop }}>
                    <Typography
                        sx={{
                            width: 'fit-content',
                            padding: '1em 1.25em',
                            paddingLeft: { xs: '2rem' },
                            background: 'rgba(0, 0, 0, 0.30)',
                            marginY: '1em',
                            borderTopRightRadius: '5px',
                            borderBottomRightRadius: '5px',
                            borderTopLeftRadius: { xs: 0, md: '5px' },
                            borderBottomLeftRadius: { xs: 0, md: '5px' },
                            color: projectTheme.palette.secondary.contrastText,
                            fontSize: { xs: '1.35rem', sm: '1.4rem', lg: '1.5rem' },
                            // letterSpacing: '0.05rem',
                            textAlign: { md: 'center' },
                        }}
                    >
                        {overview}
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent={{ xs: 'flex-start', md: 'center' }}
                        gap={'0.5em'}
                        paddingLeft="2rem"
                        paddingRight={{ xs: '0', md: '2rem' }}
                        flexWrap={'wrap'}
                    >
                        <TagTypography>{tags[0]}</TagTypography>
                        <TagTypography>{tags[1]}</TagTypography>
                        <TagTypography>{tags[2]}</TagTypography>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};

export const InfoCard = ({ title, body }) => {
    return (
        <Box>
            <SectionTitleTypography>{title}</SectionTitleTypography>
            <SectionBodyPaper>{body}</SectionBodyPaper>
        </Box>
    );
};

export const InfoCardPic = ({ flexDir = 'row', src, alt, body, mediaType = 'image' }) => {
    return (
        <Box
            sx={{
                bgcolor: projectTheme.palette.pastel.pastelBlue,
                borderRadius: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: { xs: 'column', md: flexDir },
                padding: marginMobile,
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: { lg: '1em' },
                    width: { sm: '50%' },
                    maxHeight: { xs: '200px', md: '50vw' },
                    borderRadius: 2,
                    overflow: 'hidden',
                }}
            >
                {mediaType === 'image' ? (
                    <img
                        src={src}
                        alt={alt}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            display: 'block', // Ensures image fills the Paper and prevents extra space below
                        }}
                    />
                ) : (
                    <video
                        src={src}
                        alt={alt}
                        autoPlay
                        loop
                        muted
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            display: 'block', // Ensures image fills the Paper and prevents extra space below
                        }}
                    />
                )}
            </Paper>
            <Box display={'flex'} justifyContent={'center'} width={{ sm: '70%', md: '50%' }}>
                <Typography
                    sx={{
                        fontSize: { xs: '1rem', md: '1.15rem', lg: '1.25rem' },
                        textAlign: 'center',
                        lineHeight: '170%',
                        paddingTop: { xs: '1em', md: '0' },
                        color: projectTheme.palette.secondary.main,
                        minWidth: { md: '40ch' },
                    }}
                >
                    {body}
                </Typography>
            </Box>
        </Box>
    );
};

const BenefitsCard = ({ image, imageAlt, content }) => {
    return (
        <Card
            sx={{
                borderRadius: 3,
                bgcolor: projectTheme.palette.pastel.pastelBlue,
            }}
        >
            <CardMedia
                component="img"
                image={image}
                alt={imageAlt}
                sx={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                }}
            />
            <CardContent>
                <Typography
                    sx={{
                        color: projectTheme.palette.secondary.main,
                        fontSize: { xs: '1rem', lg: '1.15rem' },
                        fontWeight: 700,
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                    gutterBottom
                >
                    {imageAlt}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: projectTheme.palette.secondary.main,
                        fontSize: { xs: '1rem', lg: '1.15rem' },
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    {content}
                </Typography>
            </CardContent>
        </Card>
    );
};

const BenefitsSection = ({ benefits }) => {
    return (
        <>
            <Carousel
                animation="slide"
                duration="1000"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: '1',
                    width: '100%',
                    height: '100%',
                }}
            >
                {benefits.map((content, index) => {
                    return (
                        <BenefitsCard
                            key={index}
                            image={content.image}
                            imageAlt={content.imageAlt}
                            content={content.description}
                        />
                    );
                })}
            </Carousel>
        </>
    );
};

export const ImportanceSection = ({ benefits, body }) => {
    const { isMediumScreen } = useScreenBreakpoints();
    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={{ xs: 'center', md: 'stretch' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            bgcolor={{ xs: '#fff', md: projectTheme.palette.pastel.pastelYellow }}
        >
            {/* LEFT why */}
            <Box
                display={'flex'}
                flexDirection={'column'}
                paddingY={{ xs: marginMobile, md: marginDesktopBetween }}
                paddingLeft={{ xs: marginMobile, lg: marginDesktop }}
                paddingRight={marginMobile}
                minWidth={{ md: '50%' }}
                maxWidth={{ md: '50%' }}
                justifyContent={'center'}
                bgcolor={projectTheme.palette.pastel.pastelYellow}
            >
                <SectionTitleTypography>Why Is This Problem Important?</SectionTitleTypography>
                <Typography
                    sx={{
                        fontSize: { xs: '1rem', md: '1.15rem', lg: '1.25rem' },
                        textAlign: 'center',
                        lineHeight: '170%',
                        paddingX: '1em',
                        color: projectTheme.palette.secondary.main,
                    }}
                >
                    {body}
                </Typography>
            </Box>
            {/* {isMediumScreen && <Divider flexItem orientation="vertical" sx={{ marginY: marginMobile, width: '3' }} />} */}
            {/* RIGHT Benefits */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: { xs: marginMobile, md: marginDesktopBetween },
                    paddingBottom: { xs: '1rem', md: '2rem' },
                    paddingRight: { xs: marginMobile, lg: marginDesktop },
                    paddingLeft: marginMobile,
                    width: '100%',
                    maxWidth: { xs: '55ch', md: 'none' },
                }}
            >
                <SectionTitleTypography>Who Will Benefit?</SectionTitleTypography>
                <BenefitsSection benefits={benefits} />
            </Box>
        </Box>
    );
};

export const SolutionsSection = ({ infoCards }) => (
    <Stack
        position={'relative'}
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"
        sx={{
            paddingY: { xs: marginMobile, md: marginDesktopBetween },
            paddingX: { xs: marginMobile, lg: marginDesktop },
            backgroundColor: { xs: projectTheme.palette.pastel.pastelYellow, md: '#fff' },
        }}
    >
        <Box
            component="img"
            src={DotsAccent2}
            sx={{
                display: { xs: 'none', sm: 'block' },
                width: { xs: '70%', md: '40%' },
                opacity: '0.3',
                top: '-3%',
                right: { xs: '-35%', md: '-12%' },
            }}
            position="absolute"
        />
        <Box
            component="img"
            src={DotsAccent2}
            sx={{
                display: { xs: 'none', sm: 'block' },
                width: { xs: '90%', md: '35%' },
                opacity: '0.3',
                bottom: { xs: '15%', md: '25%' },
                left: { xs: '-55%', md: '-15%' },
            }}
            position="absolute"
        />
        <SectionTitleTypography>How Are We Solving It?</SectionTitleTypography>
        <Stack spacing={{ xs: marginMobile, lg: marginDesktopBetween }}>
            {infoCards.map((card, index) => (
                <React.Fragment key={index}>
                    <InfoCardPic
                        src={card.src}
                        alt={card.alt}
                        flexDir={card.flexDir}
                        body={card.body}
                        mediaType={card.mediaType}
                    />
                </React.Fragment>
            ))}
        </Stack>
    </Stack>
);

export const ContactSection = ({ back, backLink, next, nextLink }) => {
    return (
        <Box
            sx={{
                paddingY: marginMobile,
                paddingX: { xs: marginMobile, lg: marginDesktop },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: `url(${Backdrop2_Join})`,
                backgroundSize: 'cover',
                backgroundColor: 'lightgray 50%',
                gap: '1rem',
            }}
        >
            <Typography
                variant="body1"
                color="projectTheme.palette.secondary.main"
                textAlign="center"
                fontSize={{ xs: '1.25rem', sm: '1.5rem' }}
                fontWeight={600}
            >
                Like what you see?
            </Typography>
            <ContactButton LinkComponent={LinkDOM} to={'/contact-us'}>
                Contact Us
            </ContactButton>
            <Typography
                variant="body1"
                color="projectTheme.palette.secondary.main,"
                textAlign="center"
                fontWeight={400}
                lineHeight="30px"
            >
                for more information and partnership inquiries.
            </Typography>
            <Box width={'100%'} display="flex" justifyContent="space-between">
                <PagerButton LinkComponent={LinkDOM} to={backLink}>
                    {back}
                </PagerButton>
                <PagerButton LinkComponent={LinkDOM} to={nextLink}>
                    {next}
                </PagerButton>
            </Box>
        </Box>
    );
};
