import React from 'react';
import { Helmet } from 'react-helmet';

import { Box, ThemeProvider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as Element } from 'react-scroll';

import Footer from 'ui-component/Footer';

import VOCSensor from '../../../assets/images/VOCSensor.webp';
import Icon_Project5 from '../../../assets/images/Icon_Project5.webp';
import DotsAccent4 from '../../../assets/images/DotsAccent4.png';
import FilterPaperDevelopment from '../../../assets/images/FilterPaperDevelopment.webp';
import eBCSensorDevelopment from '../../../assets/images/eBCSensorDevelopment.webp';
import VOCSensorDevelopment from '../../../assets/images/VOCSensorDevelopment.webp';

import For_Manufacturers from '../../../assets/images/For_Manufacturers.jpg';
import For_Entrepreneurs from '../../../assets/images/For_Entrepreneurs.jpg';
import For_Government from '../../../assets/images/For_Government.webp';

import { marginMobile, marginDesktop, projectTheme, InfoCard, marginDesktopBetween } from '../project1/projectTheme';
import { SolutionsSection, ContactSection, StartSection, ImportanceSection } from '../project1/projectTheme';

const People = () => {
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isMobileScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const benefits = [
        {
            imageAlt: 'Local Manufacturers & Distributors',
            description: 'Reduce costs and increase access to AQ sensors and filter media',
            image: For_Manufacturers,
        },
        {
            imageAlt: 'Local Entrepreneurs',
            description: "Project 5's AQ sensors could introduce new business opportunities for local entrepreneurs",
            image: For_Entrepreneurs,
        },
        {
            imageAlt: 'Government',
            description:
                'Higher resolution AQ data resulting from affordable sensors will better inform policies and AQ programs',
            image: For_Government,
            flag: true,
        },
    ];

    const infoCards = [
        {
            src: FilterPaperDevelopment,
            alt: 'Filter paper development',
            body: (
                <>
                    Project 5 is developing a locally-fabricated {isMediumScreen && <br />}
                    PM filter paper and PM filtering system for {isMediumScreen && <br />}
                    the effective and efficient collection of {isMediumScreen && <br />}
                    particulate matter. Once developed, it can {isMediumScreen && <br />}
                    also be used for P5's eBC and VOC sensors.
                </>
            ),
        },
        {
            src: eBCSensorDevelopment,
            alt: 'eBC Sensor Development',
            flexDir: 'row-reverse',
            body: (
                <>
                    Equivalent black carbon (eBC) is a {isMediumScreen && <br />}
                    component of particulate matter that is emitted {isMediumScreen && <br />}
                    from various sources such as biomass burning, {isMediumScreen && <br />}
                    traffic-related emissions, and coal combustion. {isMediumScreen && <br />}
                    Project 5 is developing an eBC sensor with a {isMediumScreen && <br />}
                    compact and weatherproof design tailored for {isMediumScreen && <br />}
                    the Philippine urban environment.
                </>
            ),
        },
        {
            src: VOCSensorDevelopment,
            alt: 'VOC Sensor Development',
            body: (
                <>
                    Volatile Organic Compounds (VOC), are serious {isMediumScreen && <br />}
                    health hazards that usually result from vehicular {isMediumScreen && <br />}
                    emissions, incomplete combustion of fuel, {isMediumScreen && <br />}
                    household cooking, cleaning, and heating, etc. {isMediumScreen && <br />}
                    Project 5 is eveloping a sensor to monitor VOCs, {isMediumScreen && <br />}
                    specifically the Benzene, Toluene, and Xylene (BTX) group.
                </>
            ),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Project 5 | UP CARE</title>
            </Helmet>
            <ThemeProvider theme={projectTheme}>
                <div style={{ overflowX: 'hidden' }}>
                    <Element name="start">
                        <StartSection
                            backdrop={VOCSensor}
                            icon={Icon_Project5}
                            projnum={5}
                            title={<>Development of Filter Media, eBC, and VOC Sensors for Local Conditions</>}
                            lead="Louis Angelo Danao, PhD"
                            overview={
                                <>
                                    Cost-effective, locally-developed {isMediumScreen && <br />}
                                    sensors to allow broader air quality {isMediumScreen && <br />}
                                    monitoring coverage for proper {isMediumScreen && <br />}
                                    modeling and prediction {isMediumScreen && <br />}
                                    of pollution levels
                                </>
                            }
                            tags={['On-Going', 'TRL 2', 'IP Protection In-Progress']}
                        />
                    </Element>
                    <Element name="problem">
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                            alignItems={'flex-end'}
                            // flexDirection={{ xs: 'column', md: 'row-reverse' }}
                            marginX={{ xs: marginMobile, lg: marginDesktop }}
                            position={'relative'}
                        >
                            {/* TOP problem */}
                            <Box
                                display={'flex'}
                                marginY={{ xs: marginMobile, md: marginDesktopBetween }}
                                minWidth={{ lg: '73ch' }}
                                justifyContent={'center'}
                            >
                                <InfoCard
                                    title="What Problem Are We Working On?"
                                    body={
                                        <>
                                            Lack of locally-developed and affordable air quality sensors.
                                            <br />
                                            <br />
                                            Using expensive sensors do increase confidence in the data{' '}
                                            {isMediumScreen && <br />}
                                            produced. However, this poses a drawback on the number of{' '}
                                            {isMediumScreen && <br />}
                                            sensors that can be deployed when high resolution, real-time{' '}
                                            {isMediumScreen && <br />}
                                            data is required for on-demand air quality information services.{' '}
                                            {isMediumScreen && <br />}
                                            Development of reliable and accurate sensors that are{' '}
                                            {isMediumScreen && <br />}
                                            affordable is necessary to address the need for more sensors{' '}
                                            {isMediumScreen && <br />}
                                            deployed in the urban landscape.
                                            {/* Using expensive sensors do increase confidence in the data produced.{' '}
                                            {isDesktopScreen && <br />}
                                            However, this poses a drawback on the number of sensors that can{' '}
                                            {isDesktopScreen && <br />}
                                            be deployed when high resolution, real-time data is required for{' '}
                                            {isDesktopScreen && <br />}
                                            on-demand air quality information services. Development of reliable{' '}
                                            {isDesktopScreen && <br />}
                                            and accurate sensors that are affordable is necessary to address{' '}
                                            {isDesktopScreen && <br />}
                                            the need for more sensors deployed in the urban landscape. */}
                                        </>
                                    }
                                />
                            </Box>
                            {/* BOTTON quote */}
                            <Box
                                width={{ sm: '100%' }}
                                minWidth={{ xs: '20ch', md: '30ch' }}
                                marginRight={marginMobile}
                                marginBottom={{ xs: marginMobile, md: marginDesktopBetween }}
                            >
                                <Typography
                                    textAlign={'right'}
                                    fontSize={{ xs: '1rem', md: '1.5' }}
                                    fontStyle="italic"
                                    fontWeight={600}
                                    lineHeight="26px"
                                    color="#52584D"
                                >
                                    “We enjoin our local government units {isMobileScreen && <br />}
                                    to collaborate with our researchers in {isMobileScreen && <br />}
                                    finding solutions in making our air cleaner {isMobileScreen && <br />}
                                    through technologies brought about {isMobileScreen && <br />}
                                    by research and development.”
                                </Typography>
                                <Typography
                                    textAlign={'right'}
                                    fontSize={'0.85rem'}
                                    letterSpacing={0.5}
                                    fontStyle="italic"
                                    fontWeight={300}
                                    lineHeight="20px"
                                    color="#52584D"
                                >
                                    Dr. Enrico Paringit
                                    <br />
                                    Executive Director, DOST-PCIEERD
                                </Typography>
                            </Box>
                            {/* Dots Accent */}
                            <Box
                                component="img"
                                src={DotsAccent4}
                                sx={{
                                    display: { xs: 'none', sm: 'block' },
                                    // maxHeight: { xs: '70%' },
                                    width: { xs: '30%', md: '35%', lg: '30%' },
                                    opacity: '0.3',
                                    bottom: { xs: '-13%', md: '0%', lg: '5%' },
                                    left: { xs: '10%', md: '7%', lg: '4%' },
                                    transform: { xs: 'rotate(-70deg)', md: 'rotate(-40deg)' },
                                    // width: { xs: '30%', md: '35%', lg: '30%' },
                                    // opacity: '0.3',
                                    // bottom: { xs: '-3%', md: '0%', lg: '5%' },
                                    // left: { xs: '10%', md: '7%', lg: '4%' },
                                    // transform: { xs: 'rotate(40deg)', md: 'rotate(-40deg)' },
                                }}
                                position="absolute"
                            />
                        </Box>
                    </Element>
                    <Element name="importance">
                        <ImportanceSection
                            benefits={benefits}
                            body={
                                <>
                                    It is important to develop local capability {isDesktopScreen && <br />}
                                    for sensor development to widen the adoption {isDesktopScreen && <br />}
                                    of affordable sensors. This wider network {isDesktopScreen && <br />}
                                    will provide the necessary data resolution {isDesktopScreen && <br />}
                                    for proper modeling and prediction of air {isDesktopScreen && <br />}
                                    pollution levels that more expensive, {isDesktopScreen && <br />}
                                    highly accurate sensors cannot address.
                                    <br />
                                    <br />
                                    The need for locally designed sensors that meet {isDesktopScreen && <br />}
                                    the demand of local conditions is imperative. {isDesktopScreen && <br />}
                                    Local conditions are different from other {isDesktopScreen && <br />}
                                    countries due to unique driving conditions, {isDesktopScreen && <br />}
                                    local regulations, and vehicle density, among {isDesktopScreen && <br />}
                                    other factors.
                                </>
                            }
                        />
                    </Element>
                    <Element name="solutions">
                        <SolutionsSection infoCards={infoCards} />
                    </Element>
                    <Element name="contact">
                        <ContactSection
                            back="< Project 4"
                            backLink="/about-us/projects/4"
                            next="Back to Projects >"
                            nextLink="/about-us/projects"
                        />
                    </Element>
                </div>
            </ThemeProvider>
            <Footer />
        </>
    );
};

export default People;
